@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
body {
  margin: 0;
  font-family: Open Sans;
  color: #121520 !important;
  background: #ffffff !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* /////////////////animation loading////////////////// */
.fadeinout {
  animation: fadeinout 1s infinite;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background: #2a2a2a;
}
::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 20px;
}

/* Wallet Model Style Start */
.gfuDAO {
  background-color: transparent !important;
  display: flex !important;
  flex-direction: column !important;
}
.iypxSt {
  flex-direction: row !important;
  align-items: center !important;
  border-radius: 10px !important;
  padding: 10px 16px !important;
  border-left: 20px solid rgb(199, 199, 199);
  border-right: 20px solid rgb(199, 199, 199);
}
.fqonLZ {
  width: 100px !important;
  height: 85px !important;
  background-color: rgb(199, 199, 199) !important;
  padding: 0px 10px;
}
.kFITWz {
  width: 0px !important;
  display: none;
}

.scroolbox::-webkit-scrollbar {
  width: 5px;
}

/* Track  */
.scroolbox::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

/* / Handle / */

.scroolbox::-webkit-scrollbar-thumb {
  background: "#000";
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
}
/* Wallet Model Style End */

/* remove input number field arrow */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
